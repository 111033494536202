import * as React from "react";
//eslint-disable-next-line
import { BrowserRouter, Routes, Route } from "react-router-dom";
import JoinNow from "./Screens/JoinNow";
import Landing from "./Screens/Landing";
import NoMatch from "./Screens/NoMatch";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/join" element={<JoinNow />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
