import React, { useState } from 'react'
import Footer from '../../Components/Footer'
import TopNav from '../../Components/TopNav'
import ClientCaptcha from "react-client-captcha";
import axios from 'axios';
import { API } from '../../config';
export default function JoinNow() {

    const [data, setData] = useState()
    const [captcha, setCaptcha] = useState()
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null)

    const handleChange = (e) => {
        setError(null)
        setMessage(null)
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        setError(null)
        setMessage(null)
        e.preventDefault()
        if (data?.captcha !== captcha) {
            setError("Please Enter Valid Captcha")
        } else {
            axios({
                method: 'post',
                url: `${API}/authRouter/register`,
                data: data
            })
                .then(function (response) {
                    setMessage(response?.data?.message);
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 5000)
                })
                .catch(function (error) {
                    setMessage(error?.response?.data?.message);
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)
                });
            setMessage("")
        }
    }

    return (
        <div>
            <TopNav />
            <div className="container-fluid p-0 mb-5">
                <div className="container-xxl py-4">
                    <div className='row'>
                        <div className='col-lg-7 col-md-12'>
                            <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                                <h1 className="mb-4">Welcome to SIRIUS</h1>
                                <p className="mb-4">At Our Training Institute, we provide the highest quality of training and education in a variety of disciplines. Whether you are looking to develop your skills in a specific field, or expand your knowledge base in a range of topics, our courses are designed to help you reach your goals.</p>
                                <p className="mb-4">Our experienced trainers are committed to helping you achieve success. We offer courses in a wide range of areas, from business and technology to healthcare and education. Our training programs are tailored to meet the needs of each individual learner.
                                </p>
                                <p className="mb-4">We strive to provide an engaging and supportive learning environment for our students. Our classes are designed with the latest teaching methods and technology, and we strive to provide the best learning experience possible.
                                </p>
                                <p className="mb-4">We offer both classroom-based and online training options. Our online courses are designed to be flexible and convenient for learners, so you can continue your education on your own time.
                                </p>
                                <p className="mb-4">We are dedicated to helping our students reach their full potential. Our knowledgeable and experienced instructors will help you reach your goals and become the best that you can be.
                                </p>
                                <p className="mb-4">We look forward to helping you reach your goals and achieving success in your chosen field. Contact us today to learn more about how we can help you reach your goals.</p>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <form onSubmit={e => handleSubmit(e)}>
                                <div className="form-group py-1">
                                    <label for="exampleInputCourse">Select Course*</label>
                                    <select name="course_name" className="form-control" id="exampleInputCourse" onChange={(e) => handleChange(e)} required>
                                        <option value="">Select One</option>
                                        <option value="Management Specialist">Management Specialist</option>
                                        <option value="Full stack Developer">Full stack Developer</option>
                                        <option value="Quality Analyst">Quality Analyst</option>
                                        <option value="Data Engineer">Data Engineer</option>
                                        <option value="ML/AI Specialist">ML/AI Specialist</option>
                                    </select>
                                </div>
                                <div className="form-group py-1">
                                    <label for="exampleInputFirstName">First Name*</label>
                                    <input name="first_name" type="text" className="form-control" id="exampleInputFirstName" placeholder="Enter First Name" onChange={(e) => handleChange(e)} required />
                                </div>
                                <div className="form-group py-1">
                                    <label for="exampleInputMiddleName">Middle Name</label>
                                    <input name="middle_name" type="text" className="form-control" id="exampleInputMiddleName" placeholder="Enter Middle Name" onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="form-group py-1">
                                    <label for="exampleInputLastName">Last Name*</label>
                                    <input name="last_name" type="text" className="form-control" id="exampleInputLastName" placeholder="Enter Last Name" onChange={(e) => handleChange(e)} required />
                                </div>
                                <div className="form-group py-1">
                                    <label for="exampleInputGender">Select Gender*</label>
                                    <select name="gender" className="form-control" id="exampleInputGender" onChange={(e) => handleChange(e)} required>
                                        <option value="">Select One</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div class="form-group py-1">
                                    <label for="exampleInputMobile">Mobile*</label>
                                    <input name="mobile" type="number" class="form-control" id="exampleInputMobile" placeholder="Enter Mobile" onChange={(e) => handleChange(e)} required />
                                </div>
                                <div class="form-group py-1">
                                    <label for="exampleInputEmail1">Email address*</label>
                                    <input name="email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email" onChange={(e) => handleChange(e)} required />
                                    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                                </div>
                                <div class="form-group py-1">
                                    <ClientCaptcha captchaCode={code => setCaptcha(code)} />
                                </div>
                                <div class="form-group py-1">
                                    <label for="exampleInputCaptcha">Enter Captcha*</label>
                                    <input name="captcha" type="text" class="form-control" id="exampleInputCaptcha" placeholder="Enter Captcha" onChange={(e) => handleChange(e)} required />
                                </div>
                                <div>
                                    <p class="text-danger">{error}</p>
                                    <p class="text-success">{message}</p>
                                </div>
                                <div class="form-group py-3">
                                    <button type="submit" className="btn btn-primary">Register</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}