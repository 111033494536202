/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Footer from '../../Components/Footer'
import TopNav from '../../Components/TopNav'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Landing() {
    return (
        <div>
            <TopNav />
            <div className="container-fluid p-0 mb-5">
                {/* <!-- About Start --> */}
                <div className="container-xxl py-5" id="about">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: '400px' }}>
                                <div className="position-relative h-100">
                                    <img className="img-fluid position-absolute w-100 h-100" src="img/about.jpg" alt="" style={{ objectFit: 'cover' }} />
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                                <h6 className="section-title bg-white text-start text-primary pe-3">About Us</h6>
                                <h1 className="mb-4">Welcome to SIRIUS</h1>
                                <p className="mb-4">At Our Training Institute, we provide the highest quality of training and education in a variety of disciplines. Whether you are looking to develop your skills in a specific field, or expand your knowledge base in a range of topics, our courses are designed to help you reach your goals.</p>
                                <p className="mb-4">Our experienced trainers are committed to helping you achieve success. We offer courses in a wide range of areas, from business and technology to healthcare and education. Our training programs are tailored to meet the needs of each individual learner.
                                </p>
                                <p className="mb-4">We strive to provide an engaging and supportive learning environment for our students. Our classes are designed with the latest teaching methods and technology, and we strive to provide the best learning experience possible.
                                </p>
                                <p className="mb-4">We offer both classroom-based and online training options. Our online courses are designed to be flexible and convenient for learners, so you can continue your education on your own time.
                                </p>
                                <p className="mb-4">We are dedicated to helping our students reach their full potential. Our knowledgeable and experienced instructors will help you reach your goals and become the best that you can be.
                                </p>
                                <p className="mb-4">We look forward to helping you reach your goals and achieving success in your chosen field. Contact us today to learn more about how we can help you reach your goals.</p>
                                <div className="row gy-2 gx-4 mb-4">
                                    {/* <div className="col-sm-6">
                                        <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Skilled Instructors</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Online classNamees</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>International Certificate</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Skilled Instructors</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Online classNamees</p>
                                    </div>
                                    <div className="col-sm-6">
                                        <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>International Certificate</p>
                                    </div> */}
                                </div>
                                <a className="btn btn-primary py-3 px-5 mt-2" href="/join">Join Now</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- About End --> */}
                {/* <!-- Service Start --> */}
                <div className="container-xxl py-5">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item text-center pt-3">
                                    <div className="p-4">
                                        
                                        <i className="fa fa-3x fa-users text-primary mb-4"></i>
                                        <h5 className="mb-3">Skilled Instructors</h5>
                                        {/* <p>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item text-center pt-3">
                                    <div className="p-4">
                                        <i className="fa fa-3x fa-cogs text-primary mb-4"></i>
                                        <h5 className="mb-3">Job Oriented Projects</h5>
                                        {/* <p>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="service-item text-center pt-3">
                                    <div className="p-4">
                                        <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                                        <h5 className="mb-3">100% Job Support</h5>
                                        {/* <p>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="service-item text-center pt-3">
                                    <div className="p-4">
                                        <i className="fa fa-3x fa-home text-primary mb-4"></i>
                                        <h5 className="mb-3">Day long SME assistance</h5>
                                        {/* <p>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                                <div className="service-item text-center pt-3">
                                    <div className="p-4">
                                        <i className="fa fa-3x fa-book-open text-primary mb-4"></i>
                                        <h5 className="mb-3">Effective Communication Skills Training to Support Global Customers</h5>
                                        {/* <p>Diam elitr kasd sed at elitr sed ipsum justo dolor sed clita amet diam</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Service End --> */}

                {/* <!-- Categories Start --> */}
                <div className="container-xxl py-5 category" id="courses">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h6 className="section-title bg-white text-center text-primary px-3">Categories</h6>
                            <h1 className="mb-5">Courses Categories</h1>
                        </div>
                        <div className="row g-3">
                            <div className="col-lg-7 col-md-6">
                                <div className="row g-3">
                                    <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                                        <a className="position-relative d-block overflow-hidden" href="">
                                            <img className="img-fluid" src="img/cat-1.jpg" alt="" />
                                            <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                                <h5 className="m-0">Fullstack Developer</h5>
                                                {/* <small className="text-primary">49 Courses</small> */}
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.3s">
                                        <a className="position-relative d-block overflow-hidden" href="">
                                            <img className="img-fluid" src="img/cat-2.jpg" alt="" />
                                            <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                                <h5 className="m-0">Management Specialist</h5>
                                                {/* <small className="text-primary">49 Courses</small> */}
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-6 col-md-12 wow zoomIn" data-wow-delay="0.5s">
                                        <a className="position-relative d-block overflow-hidden" href="">
                                            <img className="img-fluid" src="img/cat-3.jpg" alt="" />
                                            <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                                <h5 className="m-0">Quality Analyst</h5>
                                                {/* <small className="text-primary">49 Courses</small> */}
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-12 col-md-12 wow zoomIn" data-wow-delay="0.1s">
                                        <a className="position-relative d-block overflow-hidden" href="">
                                            <img className="img-fluid" src="img/cat-1.jpg" alt="" />
                                            <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                                <h5 className="m-0">Data Engineer</h5>
                                                {/* <small className="text-primary">49 Courses</small> */}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6 wow zoomIn" data-wow-delay="0.7s" style={{ minHeight: '350px' }}>
                                <a className="position-relative d-block h-100 overflow-hidden" href="">
                                    <img className="img-fluid position-absolute w-100 h-100" src="img/cat-4.jpg" alt="" style={{ objectFit: 'cover' }} />
                                    <div className="bg-white text-center position-absolute bottom-0 end-0 py-2 px-3" style={{ margin: '1px' }}>
                                        <h5 className="m-0">ML/AI Specialist</h5>
                                        {/* <small className="text-primary">49 Courses</small> */}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Categories Start --> */}
                {/* <!-- Courses Start --> */}
                {/* <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h6 className="section-title bg-white text-center text-primary px-3">Courses</h6>
                            <h1 className="mb-5">Popular Courses</h1>
                        </div>
                        <div className="row g-4 justify-content-center">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="course-item bg-light">
                                    <div className="position-relative overflow-hidden">
                                        <img className="img-fluid" src="img/course-1.jpg" alt="" />
                                        <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                            <a href="#" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end" style={{ borderRadius: '30px 0 0 30px' }}>Read More</a>
                                            <a href="#" className="flex-shrink-0 btn btn-sm btn-primary px-3" style={{ borderRadius: '0 30px 30px 0' }}>Join Now</a>
                                        </div>
                                    </div>
                                    <div className="text-center p-4 pb-0">
                                        <h3 className="mb-0">$149.00</h3>
                                        <div className="mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small>(123)</small>
                                        </div>
                                        <h5 className="mb-4">Web Design & Development Course for Beginners</h5>
                                    </div>
                                    <div className="d-flex border-top">
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-user-tie text-primary me-2"></i>John Doe</small>
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-clock text-primary me-2"></i>1.49 Hrs</small>
                                        <small className="flex-fill text-center py-2"><i className="fa fa-user text-primary me-2"></i>30 Students</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="course-item bg-light">
                                    <div className="position-relative overflow-hidden">
                                        <img className="img-fluid" src="img/course-2.jpg" alt="" />
                                        <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                            <a href="#" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end" style={{ borderRadius: '30px 0 0 30px' }}>Read More</a>
                                            <a href="#" className="flex-shrink-0 btn btn-sm btn-primary px-3" style={{ borderRadius: '0 30px 30px 0' }}>Join Now</a>
                                        </div>
                                    </div>
                                    <div className="text-center p-4 pb-0">
                                        <h3 className="mb-0">$149.00</h3>
                                        <div className="mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small>(123)</small>
                                        </div>
                                        <h5 className="mb-4">Web Design & Development Course for Beginners</h5>
                                    </div>
                                    <div className="d-flex border-top">
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-user-tie text-primary me-2"></i>John Doe</small>
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-clock text-primary me-2"></i>1.49 Hrs</small>
                                        <small className="flex-fill text-center py-2"><i className="fa fa-user text-primary me-2"></i>30 Students</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="course-item bg-light">
                                    <div className="position-relative overflow-hidden">
                                        <img className="img-fluid" src="img/course-3.jpg" alt="" />
                                        <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                            <a href="#" className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end" style={{ borderRadius: '30px 0 0 30px' }}>Read More</a>
                                            <a href="#" className="flex-shrink-0 btn btn-sm btn-primary px-3" style={{ borderRadius: '0 30px 30px 0' }}>Join Now</a>
                                        </div>
                                    </div>
                                    <div className="text-center p-4 pb-0">
                                        <h3 className="mb-0">$149.00</h3>
                                        <div className="mb-3">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small>(123)</small>
                                        </div>
                                        <h5 className="mb-4">Web Design & Development Course for Beginners</h5>
                                    </div>
                                    <div className="d-flex border-top">
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-user-tie text-primary me-2"></i>John Doe</small>
                                        <small className="flex-fill text-center border-end py-2"><i className="fa fa-clock text-primary me-2"></i>1.49 Hrs</small>
                                        <small className="flex-fill text-center py-2"><i className="fa fa-user text-primary me-2"></i>30 Students</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <!-- Courses End --> */}
                {/* <!-- Team Start --> */}
                {/* <div className="container-xxl py-5">
                    <div className="container">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h6 className="section-title bg-white text-center text-primary px-3">Instructors</h6>
                            <h1 className="mb-5">Expert Instructors</h1>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="team-item bg-light">
                                    <div className="overflow-hidden">
                                        <img className="img-fluid" src="img/team-1.jpg" alt="" />
                                    </div>
                                    <div className="position-relative d-flex justify-content-center" style={{ marginTop: '-23px' }}>
                                        <div className="bg-light d-flex justify-content-center pt-2 px-1">
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                    <div className="text-center p-4">
                                        <h5 className="mb-0">Instructor Name</h5>
                                        <small>Designation</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="team-item bg-light">
                                    <div className="overflow-hidden">
                                        <img className="img-fluid" src="img/team-2.jpg" alt="" />
                                    </div>
                                    <div className="position-relative d-flex justify-content-center" style={{ marginTop: '-23px' }}>
                                        <div className="bg-light d-flex justify-content-center pt-2 px-1">
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                    <div className="text-center p-4">
                                        <h5 className="mb-0">Instructor Name</h5>
                                        <small>Designation</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="team-item bg-light">
                                    <div className="overflow-hidden">
                                        <img className="img-fluid" src="img/team-3.jpg" alt="" />
                                    </div>
                                    <div className="position-relative d-flex justify-content-center" style={{ marginTop: '-23px' }}>
                                        <div className="bg-light d-flex justify-content-center pt-2 px-1">
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                    <div className="text-center p-4">
                                        <h5 className="mb-0">Instructor Name</h5>
                                        <small>Designation</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                                <div className="team-item bg-light">
                                    <div className="overflow-hidden">
                                        <img className="img-fluid" src="img/team-4.jpg" alt="" />
                                    </div>
                                    <div className="position-relative d-flex justify-content-center" style={{ marginTop: '-23px' }}>
                                        <div className="bg-light d-flex justify-content-center pt-2 px-1">
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-twitter"></i></a>
                                            <a className="btn btn-sm-square btn-primary mx-1" href=""><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                    <div className="text-center p-4">
                                        <h5 className="mb-0">Instructor Name</h5>
                                        <small>Designation</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <!-- Team End --> */}
                {/* <!-- Testimonial Start --> */}
                {/* <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="container">
                        <div className="text-center">
                            <h6 className="section-title bg-white text-center text-primary px-3">Testimonial</h6>
                            <h1 className="mb-5">Our Students Say!</h1>
                        </div>
                        <OwlCarousel className='owl-theme' loop margin={10} nav>
                            <div class='item'>
                                <div className="testimonial-item text-center">
                                    <img className="border rounded-circle p-2 mx-auto mb-3" src="img/testimonial-1.jpg" style={{ width: '80px', height: '80px' }} />
                                    <h5 className="mb-0">Client Name</h5>
                                    <p>Profession</p>
                                    <div className="testimonial-text bg-light text-center p-4">
                                        <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                                    </div>
                                </div>
                            </div>
                            <div class='item'>
                                <div className="testimonial-item text-center">
                                    <img className="border rounded-circle p-2 mx-auto mb-3" src="img/testimonial-4.jpg" style={{ width: '80px', height: '80px' }} />
                                    <h5 className="mb-0">Client Name</h5>
                                    <p>Profession</p>
                                    <div className="testimonial-text bg-light text-center p-4">
                                        <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                                    </div>
                                </div>
                            </div>
                            <div class='item'>
                                <div className="testimonial-item text-center">
                                    <img className="border rounded-circle p-2 mx-auto mb-3" src="img/testimonial-3.jpg" style={{ width: '80px', height: '80px' }} />
                                    <h5 className="mb-0">Client Name</h5>
                                    <p>Profession</p>
                                    <div className="testimonial-text bg-light text-center p-4">
                                        <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                                    </div>
                                </div>
                            </div>
                            <div class='item'>
                                <div className="testimonial-item text-center">
                                    <img className="border rounded-circle p-2 mx-auto mb-3" src="img/testimonial-2.jpg" style={{ width: '80px', height: '80px' }} />
                                    <h5 className="mb-0">Client Name</h5>
                                    <p>Profession</p>
                                    <div className="testimonial-text bg-light text-center p-4">
                                        <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div> */}
                {/* <!-- Testimonial End --> */}
            </div>
            <div id="contact">
                <Footer />
            </div>
        </div>
    )
}