/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function TopNav() {
    return (
        <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
            <a href="/#" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
                {/* <img src="img/logo.png" style={{ width: '200px' }} alt="" /> */}
                <h2 className="m-0" style={{ color: '#002868' }}>SIRIUS</h2>
                <h2 className="m-0" style={{ color: '#BF0A30' }}><label style={{ marginLeft: '5px' }}>TRAINERS</label></h2>
            </a>
            <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    <a href="/#" className="nav-item nav-link active">Home</a>
                    <a href="/#about" className="nav-item nav-link">About</a>
                    <a href="/#courses" className="nav-item nav-link">Courses</a>
                    {/* <div className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                        <div className="dropdown-menu fade-down m-0">
                            <a href="team.html" className="dropdown-item">Our Team</a>
                            <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                            <a href="404.html" className="dropdown-item">404 Page</a>
                        </div>
                    </div> */}
                    <a href="#contact" className="nav-item nav-link">Contact</a>
                </div>
                <a href="/join" className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">Join Now<i className="fa fa-arrow-right ms-3"></i></a>
            </div>
        </nav>
    )
}